const translation = {
  payTable: {
    "zh-tw": "賠付表",
    cn: "赔付表",
    en: "PAY TABLE",
    th: "ตารางการจ่าย",
    id: "PAY TABLE",
    vn: "PAY TABLE",
    ko: "PAY TABLE",
    es: "TABLA DE PAGOS",
    ja: "配当表",
    "pt-br": "MESA DE PAGAMENTOS",
    ph: "PAY TABLE",
  },
  totalBet: {
    "zh-tw": "總押注",
    cn: "总押注",
    en: "Total Play Amount",
    th: "เดิมพัน",
    id: "Bertaruh",
    vn: "CƯỢC",
    ko: "배팅",
    es: "Apuesta Total",
    ja: "ベット額",
    "pt-br": "Aposta Total",
    ph: "Taya",
  },
  ways: {
    "zh-tw": "路",
    cn: "路",
    en: "WAYS",
    th: "WAYS",
    id: "WAYS",
    vn: "WAYS",
    ko: "WAYS",
    es: "FORMAS",
    ja: "通り",
    "pt-br": "CAMINHOS",
    ph: "WAYS",
  },
  lines: {
    "zh-tw": "線",
    cn: "线",
    en: "LINES",
    th: "LINES",
    id: "LINES",
    vn: "LINES",
    ko: "LINES",
    es: "LÍNEAS",
    ja: "行",
    "pt-br": "LINHAS",
    ph: "LINES",
  },
  winningLines: {
    "zh-tw": "線",
    cn: "线",
    en: "Winning Lines",
    th: "Winning Lines",
    id: "Winning Lines",
    vn: "Winning Lines",
    ko: "Winning Lines",
    es: "LÍNEAS",
    ja: "行",
    "pt-br": "LINHAS",
    ph: "Winning Lines",
  },
  line: {
    "zh-tw": "線",
    cn: "线",
    en: "LINE",
    th: "LINE",
    id: "LINE",
    vn: "LINE",
    ko: "LINE",
    es: "LÍNEA",
    ja: "行",
    "pt-br": "LINHA",
    ph: "LINE",
  },
  cautions: {
    "zh-tw": "遊戲出現故障時，所有賠付和遊戲都視為無效",
    cn: "游戏出现故障时，所有赔付和游戏都视为无效。",
    en: "Malfunction Voids All Pays and Play.",
    th: "การทำงานผิดปกติจะทำให้การจ่ายเงินและการเล่นทั้งหมดเป็นโมฆะ",
    id: "Malfungsi membatalkan Bayaran dan Permainan.",
    vn: "Trả thưởng và hoạt động game vô hiệu trong trường hợp có sự cố",
    ko: "오류가 발생한 경우 해당 베팅 및 지불은 무효 처리됩니다.",
    es: "Si se produce un error de funcionamiento, se anularán todas las jugadas y pagos.",
    ja: "不具合が生じた場合、すべての配当とゲームが無効となります。",
    "pt-br": "Mau funcionamento anula todos os pagamentos e jogadas.",
    ph: "Ang Hindi Paggana ay Pinapawalang-bisa ang Lahat ng Bayad at Laro.",
  },
  reelways: {
    "zh-tw": "路路發",
    cn: "路路发",
    en: "REEL WAYS",
    th: "REEL WAYS",
    id: "REEL WAYS",
    vn: "REEL WAYS",
    ko: "REEL WAYS",
    es: "FORMAS DE RODILLO",
    ja: "路路発",
    "pt-br": "MANEIRAS DO MOLINETE",
    ph: "",
  },
  richways: {
    "zh-tw": "萬路發",
    cn: "万路发",
    en: "RICH WAYS",
    th: "RICH WAYS",
    id: "RICH WAYS",
    vn: "RICH WAYS",
    ko: "RICH WAYS",
    es: "RICH WAYS",
    ja: "万路发",
    "pt-br": "RICH WAYS",
    ph: "",
  },
  any3: {
    "zh-tw": "任意3",
    cn: "任意3",
    en: "ANY 3",
    th: "ANY 3",
    id: "ANY 3",
    vn: "ANY 3",
    ko: "ANY 3",
    es: "ANY 3",
    ja: "ANY 3",
    "pt-br": "QUALQUER 3",
    ph: "ANY 3",
  },
  any5: {
    "zh-tw": "任意5",
    cn: "任意5",
    en: "ANY 5",
    th: "ANY 5",
    id: "ANY 5",
    vn: "ANY 5",
    ko: "ANY 5",
    es: "ANY 5",
    ja: "ANY 5",
    "pt-br": "QUALQUER 5",
    ph: "ANY 5",
  },
  any6: {
    "zh-tw": "任意6",
    cn: "任意6",
    en: "ANY 6",
    th: "ANY 6",
    id: "ANY 6",
    vn: "ANY 6",
    ko: "ANY 6",
    es: "ANY 6",
    ja: "ANY 6",
    "pt-br": "QUALQUER 6",
    ph: "ANY 6",
  },
  any8: {
    "zh-tw": "任意8",
    cn: "任意8",
    en: "ANY 8",
    th: "ANY 8",
    id: "ANY 8",
    vn: "ANY 8",
    ko: "ANY 8",
    es: "ANY 8",
    ja: "ANY 8",
    "pt-br": "ANY 8",
    ph: "ANY 8",
  },
  any: {
    "zh-tw": "任意",
    cn: "任意",
    en: "ANY ",
    th: "ANY ",
    id: "ANY ",
    vn: "ANY ",
    ko: "ANY ",
    es: "ANY ",
    ja: "ANY ",
    "pt-br": "QUALQUER ",
    ph: "ANY ",
  },
  freeGameOnly: {
    "zh-tw": "只出現在免費遊戲",
    cn: "只出现在免费游戏",
    en: "FREE GAME ONLY",
    th: "ปรากฎเฉพาะในฟรีเกมเท่านั้น",
    id: "MAIN GRATIS SAJA",
    vn: "CHỈ XUẤT HIỆN Ở VÒNG QUAY MIỄN PHÍ",
    ko: "프리게임만 진행 합니다.",
    es: "SOLO JUEGO GRATIS",
    ja: "フリーゲーム限定",
    "pt-br": "APENAS JOGO GRATUITO",
    ph: "LIBRENG LARO LAMANG",
  },
};

export default translation;
