export const currencyList = {
  default: {
    id: "0",
    symbol: "",
  },
  cny: {
    id: "1",
    symbol: "¥",
  },
  twd: {
    id: "2",
    symbol: "$",
  },
  usd: {
    id: "3",
    symbol: "$",
  },
  krw: {
    id: "4",
    symbol: "₩",
  },
  jpy: {
    id: "5",
    symbol: "¥",
  },
  php: {
    id: "6",
    symbol: "₱",
  },
  thb: {
    id: "7",
    symbol: "฿",
  },
  myr: {
    id: "8",
    symbol: "[rm]",
  },
  vnd: {
    id: "9",
    symbol: "[vnd]",
  },
  eur: {
    id: "10",
    symbol: "€",
  },
  mmk: {
    id: "11",
    symbol: "K",
  },
  idr: {
    id: "12",
    symbol: "[rp]",
  },
  aud: {
    id: "13",
    symbol: "$",
  },
  sgd: {
    id: "14",
    symbol: "$",
  },
  gbp: {
    id: "15",
    symbol: "£",
  },
  inr: {
    id: "16",
    symbol: "₹",
  },
  "vnd(k)": {
    id: "17",
    symbol: "[vndk]",
  },
  "idr(k)": {
    id: "18",
    symbol: "[rpk]",
  },
  hkd: {
    id: "19",
    symbol: "[hk]",
  },
  cqp2: {
    id: "20",
    symbol: "",
  },
  mmkp1: {
    id: "21",
    symbol: "",
  },
  cfc: {
    id: "22",
    symbol: "[cfc]",
  },
  rub: {
    id: "23",
    symbol: "₽",
  },
  pln: {
    id: "24",
    symbol: "zł",
  },
  dimc: {
    id: "25",
    symbol: "[dimc]",
  },
  godc: {
    id: "26",
    symbol: "[godc]",
  },
  mbtc: {
    id: "27",
    symbol: "[mbtc]",
  },
  meth: {
    id: "28",
    symbol: "[meth]",
  },
  eos: {
    id: "29",
    symbol: "[eos]",
  },
  mltc: {
    id: "30",
    symbol: "[mltc]",
  },
  khr: {
    id: "31",
    symbol: "៛",
  },
  ygb: {
    id: "32",
    symbol: "[ygb]",
  },
  egc: {
    id: "33",
    symbol: "",
  },
  "khr(moha)": {
    id: "34",
    symbol: "",
  },
  sek: {
    id: "35",
    symbol: "[sek]",
  },
  nok: {
    id: "36",
    symbol: "[nok]",
  },
  cad: {
    id: "37",
    symbol: "[cad]",
  },
  usdx: {
    id: "38",
    symbol: "[usdx]",
  },
  ezgc: {
    id: "39",
    symbol: "",
  },
  blc: {
    id: "40",
    symbol: "[blc]",
  },
  ct: {
    id: "41",
    symbol: "[ct]",
  },
  dccb: {
    id: "42",
    symbol: "[dccb]",
  },
  otc: {
    id: "43",
    symbol: "[otc]",
  },
  "dscb(k)": {
    id: "44",
    symbol: "[dscbk]",
  },
  hda: {
    id: "45",
    symbol: "[hda]",
  },
  poc: {
    id: "46",
    symbol: "[poc]",
  },
  usdt: {
    id: "47",
    symbol: "[usdt]",
  },
  mbhd: {
    id: "48",
    symbol: "[mbhd]",
  },
  meos: {
    id: "49",
    symbol: "[meos]",
  },
  mxn: {
    id: "50",
    symbol: "[mxn]",
  },
  wicks: {
    id: "51",
    symbol: "",
  },
  bb$: {
    id: "52",
    symbol: "[bb]",
  },
  "mmk(100)": {
    id: "53",
    symbol: "[mmk100]",
  },
  bet: {
    id: "54",
    symbol: "[bet]",
  },
  brl: {
    id: "55",
    symbol: "R$",
  },
  kes: {
    id: "56",
    symbol: "[kes]",
  },
  "inr(0.01)": {
    id: "57",
    symbol: "",
  },
  zar: {
    id: "58",
    symbol: "R",
  },
  ils: {
    id: "59",
    symbol: "₪",
  },
  try: {
    id: "60",
    symbol: "₺",
  },
  mgc: {
    id: "61",
    symbol: "[mgc]",
  },
  bnd: {
    id: "62",
    symbol: "B$",
  },
  "mmk(k)": {
    id: "63",
    symbol: "[mmkk]",
  },
  cop: {
    id: "64",
    symbol: "[cop]",
  },
  bdt: {
    id: "65",
    symbol: "৳",
  },
  "usdt(0.1)": {
    id: "66",
    symbol: "[usdt01]",
  },
  clp: {
    id: "67",
    symbol: "$",
  },
  ubtc: {
    id: "68",
    symbol: "[ubtc]",
  },
  doge: {
    id: "69",
    symbol: "Ð",
  },
  pkr: {
    id: "70",
    symbol: "PKR",
  },
  pk5: {
    id: "71",
    symbol: "Rs",
  },
  lkr: {
    id: "72",
    symbol: "Rs",
  },
  irr: {
    id: "73",
    symbol: "﷼",
  },
  nzd: {
    id: "74",
    symbol: "$",
  },
  uah: {
    id: "75",
    symbol: "₴",
  },
  kzt: {
    id: "76",
    symbol: "T",
  },
  uzs: {
    id: "77",
    symbol: "",
  },
  azn: {
    id: "78",
    symbol: "₼",
  },
  gel: {
    id: "79",
    symbol: "₾",
  },
  amd: {
    id: "80",
    symbol: "[amd]",
  },
  kgs: {
    id: "81",
    symbol: "[kgs]",
  },
  tmt: {
    id: "82",
    symbol: "",
  },
  byn: {
    id: "83",
    symbol: "Br",
  },
  czk: {
    id: "84",
    symbol: "Kč",
  },
  chf: {
    id: "85",
    symbol: "[chf]",
  },
  mdl: {
    id: "86",
    symbol: "L",
  },
  huf: {
    id: "87",
    symbol: "ft",
  },
  kez: {
    id: "88",
    symbol: "",
  },
  tzs: {
    id: "89",
    symbol: "Sh",
  },
  zmw: {
    id: "90",
    symbol: "",
  },
  tnd: {
    id: "91",
    symbol: "TND",
  },
  ngn: {
    id: "92",
    symbol: "₦",
  },
  ugx: {
    id: "93",
    symbol: "",
  },
  xaf: {
    id: "94",
    symbol: "Sh",
  },
  ghs: {
    id: "95",
    symbol: "₵",
  },
  pen: {
    id: "96",
    symbol: "S/.",
  },
  in5: {
    id: "97",
    symbol: "",
  },
  kvdn: {
    id: "98",
    symbol: "",
  },
  kidr: {
    id: "99",
    symbol: "",
  },
  trx: {
    id: "100",
    symbol: "TRX",
  },
  npr: {
    id: "101",
    symbol: "NPR",
  },
  lak: {
    id: "102",
    symbol: "₭",
  },
  aed: {
    id: "103",
    symbol: "AED",
  },
  "usd(0.1)": {
    id: "104",
    symbol: "$",
  },
  tw: {
    id: "105",
    symbol: "NT$",
  },
  ves: {
    id: "106",
    symbol: "VES",
  },
  kmy: {
    id: "107",
    symbol: "KMY",
  },
  ars: {
    id: "108",
    symbol: "ARS$",
  },
};
