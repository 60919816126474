export const linesList = [
  "1",
  "5",
  "5_2",
  "8",
  "9",
  "9_2",
  "10",
  "10_2",
  "10_3",
  "10_4",
  "10_5",
  "15",
  "15_2",
  "20",
  "20_2",
  "25",
  "25_2",
  "25_3",
  "30",
  "40",
  "40_2",
  "50",
  "50_2",
  "60",
  "88",
];
export const waysList = [
  "108",
  "243",
  "576",
  "576_2",
  "720",
  "1024",
  "4096",
  "4096_2",
  "any3",
  "any5",
  "any6",
  "any8",
  "3x5ways",
  "4x5ways",
  "richways",
  "richways2",
];
