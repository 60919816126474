export const localeList = {
  cn: {
    key: "cn",
    title: "简体中文",
    img: (imgsdomain = "") => `${imgsdomain}/help/common/lang/cn.png`,
  },
  en: {
    key: "en",
    title: "English",
    img: (imgsdomain = "") => `${imgsdomain}/help/common/lang/en.png`,
  },
  th: {
    key: "th",
    title: "ประเทศไทย",
    img: (imgsdomain = "") => `${imgsdomain}/help/common/lang/th.png`,
  },
  id: {
    key: "id",
    title: "Indonesia",
    img: (imgsdomain = "") => `${imgsdomain}/help/common/lang/id.png`,
  },
  vn: {
    key: "vn",
    title: "Việt nam",
    img: (imgsdomain = "") => `${imgsdomain}/help/common/lang/vn.png`,
  },
  ko: {
    key: "ko",
    title: "한국인",
    img: (imgsdomain = "") => `${imgsdomain}/help/common/lang/kr.png`,
  },
  es: {
    key: "es",
    title: "España",
    img: (imgsdomain = "") => `${imgsdomain}/help/common/lang/es.png`,
  },
  ja: {
    key: "ja",
    title: "日本語",
    img: (imgsdomain = "") => `${imgsdomain}/help/common/lang/jp.png`,
  },
  "pt-br": {
    key: "pt-br",
    title: "Português brasileiro",
    img: (imgsdomain = "") => `${imgsdomain}/help/common/lang/pt.png`,
  },
  ph: {
    key: "ph",
    title: "Pilipinas",
    img: (imgsdomain = "") => `${imgsdomain}/help/common/lang/ru.png`,
  },
  "zh-tw": {
    key: "zh-tw",
    title: "繁體中文",
    img: (imgsdomain = "") => `${imgsdomain}/help/common/lang/zh-tw.jpg`,
  },
};
